import React, { useContext, useEffect, useMemo } from 'react';
import styled, { createGlobalStyle, css, ThemeContext, ThemeProvider } from 'styled-components';
import {
  dimensions,
  PrintContainerDimensions,
  StudioAllocationPanel,
  StudioContext,
  UnsavedChangesTrigger,
  StudioShimmerBlock,
  AgGridThemeOverrides,
  StudioPrivatesAllocationPanel,
  useAppPrintMode,
  StudioEmptyAllocationPanel,
} from 'venn-components';
import { useHasFF, useRedrawCharts } from 'venn-utils';
import type { Theme } from 'venn-ui-kit';
import { ColorUtils, Display1, ExternalActivityListener, GetColor, getTypographyTheme } from 'venn-ui-kit';
import BuilderList from './BuilderList';
import { Content, ContentCover, ContentStyle, LeftPanel, PageContent, TopControl } from './shared';
import { StudioMainLeftPanel } from './studio-main-left-panel';
import TopBar from './top-bar/TopBar';
import { TOP_TOOLBAR_HEIGHT } from './top-bar/shared';
import SetupConfigurationModal from './setup-configuration-modal/SetupConfigurationModal';
import { FirstTimeAISModal } from './FirstTimeAISModal';
import { useRecoilState, useRecoilValue, waitForAll } from 'recoil';
import {
  studioIsPortrait,
  studioLeftPanelOpen,
  studioPrintOrientationType,
  viewPages,
  openAllocatorSubject,
  compareColumnOpen,
  hasAllocationPercentError,
  hasUnsavedPortfolioChangesInAllocator,
  useRecoilValueWithDefault,
  studioLeftBlockConfigPanelExpandedState,
  CONTENT_PADDING,
  selectedBlockIdState,
  isReportState,
  openPrivateAllocatorPortfolio,
  hasUnsavedChangesInPrivatesAllocator,
  SyncSubjectsToCustomFields,
  allBlockIdsState,
  blockGridStyleState,
  analysisViewIdState,
  hasUnsavedChangesState,
  isAllocatorOpenState,
} from 'venn-state';
import { StudioBlockConfigPanelContent } from './studio-right-panel/StudioBlockConfigPanelContent';
import { UnsavedChangesModal } from './UnsavedChangesModal';
import { useVirtualization } from '../logic/useVirtualization';
import { InaccessibleSubjectsWarningModal } from './InaccessibleSubjectsWarningModal';
import { DebugPrintPage } from './debug-print-page/DebugPrintPage';
import { noop } from 'lodash';
import { RedesignedTopBar } from './top-bar/RedesignedTopBar';

const allBlocksShimmer = <StudioShimmerBlock height={400} />;

/**
 * The main content for both Studio and Report Lab, containing the blocks/pages of the view and configuration side panels.
 *
 * Note: individual context props are passed in one-by-one to prevent unnecessary rerenders from studio context from propagating unnecessarily.
 */
const StudioMainContent = React.memo(function StudioMainContent(props: { inPrintMode: boolean; isReport: boolean }) {
  const theme = useContext(ThemeContext);
  const isVirtualized = useVirtualization();
  const studioTheme: Theme = useMemo(() => {
    const selectedStudioTheme = window.sessionStorage.getItem('studioTheme') ?? 'default';
    return {
      ...theme,
      Typography: getTypographyTheme(selectedStudioTheme),
    };
  }, [theme]);

  const builderListWithDimensions = React.useCallback(
    ({ width }: { width: number; print: boolean }): JSX.Element => (
      <BuilderList width={Math.floor(width - (props.inPrintMode ? 0 : CONTENT_PADDING * 2))} />
    ),
    [props.inPrintMode],
  );

  return (
    <ThemeProvider theme={studioTheme}>
      {/* TODO(VENN-24677): delete useless StyledExternalActivityListener, but be careful to keep overflow behavior */}
      <StyledExternalActivityListener allowOverflow={!isVirtualized} onExternalActivity={noop}>
        <Content>
          <React.Suspense fallback={allBlocksShimmer}>
            <Blocks inPrintMode={props.inPrintMode}>
              <DebugPrintPage />
              <PrintContainerDimensions>{builderListWithDimensions}</PrintContainerDimensions>
            </Blocks>
          </React.Suspense>
        </Content>
      </StyledExternalActivityListener>
    </ThemeProvider>
  );
});

/**
 * Displays side panels of Builder (Studio, Report Lab), such as the left panel, block configuration panel, and allocator panel.
 *
 *  Note: individual context props are passed in one-by-one to prevent unnecessary rerenders caused by studio context from propagating unnecessarily.
 */
const StudioPanels = React.memo(function StudioPanels({
  inPrintMode,
  isLeftBlockConfigPanelOpen,
  isAllocatorPanelOpen,
}: {
  inPrintMode: boolean;
  isLeftBlockConfigPanelOpen: boolean;
  isAllocatorPanelOpen: boolean;
}) {
  const hasApInRl = useHasFF('ap_in_rl_ff');

  const selectedBlockId = useRecoilValue(selectedBlockIdState);
  const isPrivateAllocatorOpen = !!useRecoilValue(openPrivateAllocatorPortfolio);
  const isAllocatorOpen = !!useRecoilValue(openAllocatorSubject);
  const hasAllocationError = useRecoilValue(hasAllocationPercentError);

  return (
    <>
      {!inPrintMode && isAllocatorPanelOpen && (
        <>
          <AllocationPanelWrapper hasApInRl={hasApInRl}>
            {isPrivateAllocatorOpen ? (
              <StudioPrivatesAllocationPanel />
            ) : isAllocatorOpen ? (
              <StudioAllocationPanel />
            ) : (
              <StudioEmptyAllocationPanel />
            )}
          </AllocationPanelWrapper>
          {hasAllocationError && (
            <ContentCover>
              <Display1>To resume analysis, ensure investment allocations sum up to 100%</Display1>
            </ContentCover>
          )}
        </>
      )}
      {!inPrintMode && (
        <LeftPanel>
          <StudioMainLeftPanel />
        </LeftPanel>
      )}
      {!inPrintMode && selectedBlockId && isLeftBlockConfigPanelOpen && (
        <BlockConfigPanelWrapper hasApInRl={hasApInRl}>
          <StudioBlockConfigPanelContent selectedBlockId={selectedBlockId} />
        </BlockConfigPanelWrapper>
      )}
    </>
  );
});

const StudioContent = React.memo(function StudioContent() {
  const hasApInRl = useHasFF('ap_in_rl_ff');
  const hasTopBarRedesign = useHasFF('redesigned_top_bar_ff');

  const isMainLeftPanelOpen = useRecoilValue(studioLeftPanelOpen);
  const reportLabPages = useRecoilValue(viewPages);
  const hasUnsavedReturnsAllocatorChanges = useRecoilValueWithDefault(hasUnsavedPortfolioChangesInAllocator, false);
  const hasUnsavedPrivateAllocatorChanges = useRecoilValueWithDefault(hasUnsavedChangesInPrivatesAllocator, false);
  const hasUnsavedAllocatorChanges = hasUnsavedReturnsAllocatorChanges || hasUnsavedPrivateAllocatorChanges;
  const { inPrintMode } = useAppPrintMode();
  const { afterUnsavedChangesAction, isOpenReportConfigModal, openReportConfigModal, closeReportConfigModal } =
    useContext(StudioContext);
  const viewId = useRecoilValue(analysisViewIdState);
  const isReport = useRecoilValue(isReportState);

  const subjectInAllocator = useRecoilValue(openAllocatorSubject);
  const privatePortfolioInAllocator = useRecoilValue(openPrivateAllocatorPortfolio);

  const isCompareOpen = useRecoilValue(compareColumnOpen);
  const isAllocatorOpen = useRecoilValue(isAllocatorOpenState);
  const isAllocatorPanelOpen =
    (!isReport || hasApInRl) && (isAllocatorOpen || !!subjectInAllocator || !!privatePortfolioInAllocator);
  const selectedBlockId = useRecoilValue(selectedBlockIdState);
  const isLeftBlockConfigPanelOpen = !!selectedBlockId && (!isAllocatorPanelOpen || hasApInRl);
  const isOriginalAISOrg = useHasFF('original_ais_org');
  const hasUnsavedChanges = useRecoilValueWithDefault(hasUnsavedChangesState, false);

  const [isLeftBlockConfigPanelExpanded, setIsLeftBlockConfigPanelExpanded] = useRecoilState(
    studioLeftBlockConfigPanelExpandedState,
  );
  useEffect(() => {
    if (isLeftBlockConfigPanelOpen) {
      // When the panel gets opened from being closed previously, expand it by default
      setIsLeftBlockConfigPanelExpanded(true);
    }
  }, [isLeftBlockConfigPanelOpen, setIsLeftBlockConfigPanelExpanded]);

  const printOrientationType = useRecoilValue(studioPrintOrientationType);
  const isPortrait = useRecoilValue(studioIsPortrait);
  const pageSize = `A4 ${isPortrait ? 'portrait' : 'landscape'}`;

  const { totalHeight: pageHeight } = dimensions[printOrientationType];
  const printContentHeight = isReport ? reportLabPages.length * pageHeight : undefined;

  useRedrawCharts(isLeftBlockConfigPanelOpen, isAllocatorPanelOpen, isLeftBlockConfigPanelExpanded);

  const blockIds = useRecoilValue(allBlockIdsState);
  const blockGridStyles = useRecoilValue(waitForAll(blockIds.map((id) => blockGridStyleState(id))));

  return (
    <UnsavedChangesTrigger isNotSaved={hasUnsavedChanges || hasUnsavedAllocatorChanges} ignoreSearchParam>
      {({ isOpen: isUnsavedChangesModalOpen, handleConfirmNavigation, handleReject }) => (
        <>
          {!isOriginalAISOrg && <FirstTimeAISModal />}
          {(isUnsavedChangesModalOpen || afterUnsavedChangesAction) && (
            <UnsavedChangesModal onProceed={handleConfirmNavigation} onReject={handleReject} />
          )}
          <SyncSubjectsToCustomFields />
          <ContentWrapper className="studio-content-wrapper" height={printContentHeight}>
            <StudioStyle pageSize={pageSize} />
            <AgGridThemeOverrides customFontSizeEnabled blockGridStyles={blockGridStyles} />
            <PageContent
              hasApInRl={hasApInRl}
              isMainLeftPanelOpen={isMainLeftPanelOpen}
              isAllocatorOpen={isAllocatorPanelOpen}
              isCompareOpen={isCompareOpen}
              isLeftBlockConfigPanelOpen={isLeftBlockConfigPanelOpen}
              isLeftBlockConfigPanelExpanded={isLeftBlockConfigPanelExpanded}
              inPrintMode={inPrintMode}
              orientation={printOrientationType}
            >
              {!inPrintMode && (
                <TopControl>
                  <SaveWrapper>
                    {hasTopBarRedesign ? (
                      <RedesignedTopBar openReportConfigModal={openReportConfigModal} />
                    ) : (
                      <TopBar openReportConfigModal={openReportConfigModal} />
                    )}
                  </SaveWrapper>
                </TopControl>
              )}

              <StudioPanels
                inPrintMode={inPrintMode}
                isLeftBlockConfigPanelOpen={isLeftBlockConfigPanelOpen}
                isAllocatorPanelOpen={isAllocatorPanelOpen}
              />

              <StudioMainContent inPrintMode={inPrintMode} isReport={!!isReport} />

              {!inPrintMode && isOpenReportConfigModal && <SetupConfigurationModal onCancel={closeReportConfigModal} />}
              {viewId && <InaccessibleSubjectsWarningModal viewId={viewId} />}
            </PageContent>
          </ContentWrapper>
        </>
      )}
    </UnsavedChangesTrigger>
  );
});

export default StudioContent;

const secondaryLeftPanelMixin = css<{ hasApInRl: boolean }>`
  background-color: ${GetColor.WhiteGrey};
  padding-left: ${({ hasApInRl }) => (hasApInRl ? 0 : 7)}px;
  height: 100%;
`;

const BlockConfigPanelWrapper = styled.div<{ hasApInRl: boolean }>`
  ${secondaryLeftPanelMixin}
  grid-area: blockConfigPanel;
  overflow: auto;
`;

const AllocationPanelWrapper = styled.div<{ hasApInRl: boolean }>`
  ${secondaryLeftPanelMixin}

  grid-area: allocator;
  position: relative;
  width: unset;
  display: flex;
  overflow: hidden;
`;

const SaveWrapper = styled.div`
  height: ${TOP_TOOLBAR_HEIGHT};
`;

const Blocks = styled.div<{ inPrintMode?: boolean }>`
  background-color: ${ColorUtils.hex2rgbaFrom(GetColor.WhiteGrey, 0.5)};
  ${({ inPrintMode }) =>
    inPrintMode &&
    css`
      background-color: transparent;
      padding: 0;
    `}
`;

const ContentWrapper = styled.div<{ height?: number }>`
  overflow-x: hidden;
  position: relative;
  margin: 0 auto;
  width: 100%;

  @media print {
    display: block !important;
  }
`;

const StudioStyle = createGlobalStyle<{ pageSize: string }>`
  /* Printing */
  @media print {
    size: ${({ pageSize }) => pageSize};
  }

  @page {
    size: ${({ pageSize }) => pageSize};
    margin: 0;
    padding: 0;
  }
`;

const StyledExternalActivityListener = styled(ExternalActivityListener)<{ allowOverflow: boolean }>`
  ${({ allowOverflow }) =>
    allowOverflow &&
    css`
      overflow-y: auto;
    `}
  ${ContentStyle}
`;
