import React from 'react';
import { useRecoilCallback, useRecoilValue, type CallbackInterface } from 'recoil';
import {
  firstPortfolioInView,
  isAllocatorOpenState,
  openAllocatorSubject,
  openPrivateAllocatorPortfolio,
  analysisSubjectQuery,
  isAnyAllocatorOpen,
  openAllocatorSubjectConfig,
  allocatorAnalysisSubject,
  type Subject,
} from 'venn-state';
import { topBarButton } from './shared';

export const AllocationsButton = React.memo(() => {
  const isAllocatorPanelOpen = useRecoilValue(isAnyAllocatorOpen);

  const onToggleAllocator = useRecoilCallback(toggleAllocator, []);

  return (
    <button type="button" className={topBarButton({ active: isAllocatorPanelOpen })} onClick={onToggleAllocator}>
      Allocations
    </button>
  );
});
AllocationsButton.displayName = 'AllocationsButton';

/**
 * Recoil callback to toggle the allocator panel.
 *
 * When opening, opens to the first available portfolio in the view, if any.
 */
function toggleAllocator(recoilProps: CallbackInterface) {
  const { snapshot, set } = recoilProps;
  return async () => {
    const isAllocatorPanelOpen = snapshot.getLoadable(isAnyAllocatorOpen).valueMaybe();
    if (isAllocatorPanelOpen) {
      set(isAllocatorOpenState, false);
      closePortfolio(recoilProps)();
    } else {
      await tryOpenFirstPortfolio(recoilProps)();
      set(isAllocatorOpenState, true);
    }
  };
}

/** Close the currently open portfolio. */
function closePortfolio(recoilProps: CallbackInterface) {
  const { snapshot, set } = recoilProps;
  return () => {
    const currentSubject = snapshot.getLoadable(openAllocatorSubject).valueMaybe();
    set(openAllocatorSubject, undefined);
    currentSubject && set(allocatorAnalysisSubject(currentSubject), undefined);
    set(openAllocatorSubjectConfig, undefined);
    set(openPrivateAllocatorPortfolio, undefined);
  };
}

/** Recoil callback to open the first portfolio in view, or do nothing if there isn't one. */
function tryOpenFirstPortfolio(recoilProps: CallbackInterface) {
  const { snapshot } = recoilProps;
  return async () => {
    const firstPortfolio = await snapshot.getPromise(firstPortfolioInView);
    const shouldOpenFirstPortfolio =
      firstPortfolio &&
      !snapshot.getLoadable(openPrivateAllocatorPortfolio).valueMaybe() &&
      !snapshot.getLoadable(openAllocatorSubject).valueMaybe();
    if (shouldOpenFirstPortfolio) {
      await openPortfolio(recoilProps)(firstPortfolio);
    }
  };
}

/** Recoil callback to open the provided portfolio. */
function openPortfolio(recoilProps: CallbackInterface) {
  const { snapshot, set } = recoilProps;
  return async (portfolio: Subject) => {
    if (portfolio.portfolioId) {
      set(openAllocatorSubject, portfolio);
    } else if (portfolio.privatePortfolioId) {
      const firstPortfolioSubject = await snapshot.getPromise(analysisSubjectQuery(portfolio));
      set(openPrivateAllocatorPortfolio, firstPortfolioSubject.privatePortfolio);
    } else {
      throw new Error('Invalid portfolio');
    }
  };
}
