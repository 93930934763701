import React, { useContext } from 'react';
import styled from 'styled-components';
import type { DropMenuItem } from 'venn-ui-kit';
import { GetColor, Icon } from 'venn-ui-kit';
import type { Portfolio } from 'venn-api';
import { Header } from './Layout';
import PercentageToggle from './PercentageToggle';
import TradesToggle from './TradesToggle';
import type { CompareType } from '../contexts';
import { OptimalPortfolioContext, UserContext } from '../contexts';
import PortfolioCompareSubjectSelector from './compare-selector/PortfolioCompareSubjectSelector';
import { noop } from 'lodash';

export interface AllocationHeaderProps {
  selected?: CompareType;
  masterName?: string;
  hideMaster?: boolean;
  lastSavedPortfolio?: Portfolio;
  // We won't allow user to toggle percentage mode when the total is 0
  invalidTotal?: boolean;
  hasAllocationError?: boolean;
  isPercentageMode?: boolean;
  comparisonColor: string;
  lightComparisonColor: string;
  isTradesView?: boolean;
  tradesToggleDisabled?: boolean;
  optimizationEnabled?: boolean;
  toggleTradesView?: () => void;
  togglePercentageMode?: (value: boolean) => void;
  onChangeComparison?: (option: DropMenuItem<CompareType>, specificPortfolioVersion?: Portfolio) => void;
  onMouseEnter?: (e: React.MouseEvent<HTMLDivElement>) => void;
  onMouseLeave?: (e: React.MouseEvent<HTMLDivElement>) => void;
  disabledOptimizationMessage?: string;
  hideComparisonColumn?: boolean;
}

const AllocationHeader = ({
  masterName,
  hideMaster,
  onChangeComparison,
  selected = 'None',
  onMouseEnter,
  onMouseLeave,
  lastSavedPortfolio,
  isPercentageMode,
  togglePercentageMode,
  hasAllocationError,
  invalidTotal,
  comparisonColor,
  lightComparisonColor,
  tradesToggleDisabled,
  isTradesView,
  toggleTradesView,
  optimizationEnabled,
  disabledOptimizationMessage,
  hideComparisonColumn,
}: AllocationHeaderProps) => {
  const { hasPermission } = useContext(UserContext);
  const canOptimize = hasPermission('OPTIMIZATION');

  const options = getOptions(hideMaster, optimizationEnabled, masterName, disabledOptimizationMessage, canOptimize);
  const errorMessage = hasAllocationError
    ? 'Ensure allocations sum up to 100%'
    : invalidTotal
      ? 'Add at least one non-zero allocation'
      : undefined;
  return (
    <Header onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <HeaderRow>
        <Main>
          <Name>Name</Name>
          <Navs>
            <Current>Current</Current>
            <PercentageToggle
              disabled={!!errorMessage}
              tooltipMessage={errorMessage}
              toggled={isPercentageMode}
              onToggle={() => togglePercentageMode?.(!isPercentageMode)}
            />
          </Navs>
        </Main>
        {!hideComparisonColumn && (
          <Comparison color={comparisonColor}>
            <PortfolioCompareSubjectSelector
              lastSavedPortfolio={lastSavedPortfolio}
              comparisonColor={comparisonColor}
              options={options}
              selectedValue={selected}
              onChange={onChangeComparison ?? noop}
            />
          </Comparison>
        )}
      </HeaderRow>

      <HeaderRow>
        <EmptySpace />
        {!hideComparisonColumn && (
          <Comparison color={lightComparisonColor} dense>
            <Trades>Trades</Trades>
            <TradesToggle disabled={tradesToggleDisabled} toggled={isTradesView} onToggle={toggleTradesView} />
          </Comparison>
        )}
      </HeaderRow>
    </Header>
  );
};

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
export default (props: AllocationHeaderProps) => (
  <OptimalPortfolioContext.Consumer>
    {({ hasAccessToOptimization }) => <AllocationHeader {...props} optimizationEnabled={hasAccessToOptimization} />}
  </OptimalPortfolioContext.Consumer>
);

const getOptions = (
  hideMaster?: boolean,
  optimizationEnabled?: boolean,
  masterName?: string,
  disabledOptimizationMessage?: string,
  canOptimize?: boolean,
): DropMenuItem[] => {
  const options: DropMenuItem[] = [{ label: 'None', value: 'None', className: 'qa-option-none' }];
  if (!hideMaster) {
    options.push({
      label: masterName ? `Master (${masterName})` : 'Master',
      value: 'Master',
      className: 'qa-option-master',
    });
  }
  if (optimizationEnabled && canOptimize) {
    const disabledProps = disabledOptimizationMessage
      ? { disabled: true, tooltipContent: disabledOptimizationMessage }
      : {};
    const portfolioLabProps = {
      label: 'Optimized with Portfolio Lab',
      icon: <FlaskIcon type="flask" />,
      description: 'Metrics driven by your portfolio policy, historical data, and long-term forecasts.',
    };
    options.push({
      value: 'Optimized',
      className: 'qa-option-optimized',
      ...disabledProps,
      ...portfolioLabProps,
    });
  }
  return options;
};

const HeaderRow = styled.div`
  display: flex;
  width: 100%;
  color: ${GetColor.White};
  font-size: 12px;
`;

const EmptySpace = styled.div`
  flex-grow: 1;
  background-color: ${GetColor.GreyScale.Grey10};
`;

const Trades = styled.div`
  font-size: 12px;
  font-weight: bold;
  color: ${GetColor.Black};
  margin-right: 4px;
`;

const Main = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  border-top: 1px solid ${GetColor.GreyScale.Grey30};
  border-bottom: 1px solid ${GetColor.GreyScale.Grey30};
  color: ${GetColor.Black};
  font-weight: bold;
`;

const Name = styled.div`
  width: 200px;
  padding-left: 8px;
`;

const Navs = styled.div`
  width: 105px;
  text-align: end;
  display: flex;
  align-items: center;
`;

const Current = styled.span`
  margin-right: 10px;
`;

const Comparison = styled.div<{ color: string; dense?: boolean }>`
  width: 105px;
  background-color: ${({ color }) => color};
  height: ${({ dense }) => (dense ? 27 : 35)}px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FlaskIcon = styled(Icon)`
  margin-right: 4px;
`;
