import React, { useCallback, useContext, useLayoutEffect, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import type { AllocationConstraint, OptimizationConfiguration, Portfolio, PortfolioSummary } from 'venn-api';
import { updatePortfolioV3 } from 'venn-api';
import {
  ColorUtils,
  DEMO_PORTFOLIO_FAQ_HREF,
  getAppTitle,
  GetColor,
  Icon,
  LoadingSize,
  Notifications,
  NotificationType,
  Spinner,
  Tooltip,
  TooltipPosition,
} from 'venn-ui-kit';
import {
  analyticsService,
  isPortfolioEditable,
  logExceptionIntoSentry,
  recursiveClearBenchmarks,
  recursiveUpdateAllocation,
  recursiveUpdatePortfolioName,
  showReadOnlyWarning,
  useModal,
} from 'venn-utils';
import PortfoliosContext from '../contexts/portfolios-context';
import ToolbarButton from '../toolbar-button/ToolbarButton';
import { Constants, Footer } from './Layout';
import { CreateMasterPortfolioModal, UpdateMasterPortfolioModal } from '../modals';
import OptimizationConstraintsButton, {
  CompareColumnButton,
  qaApplyAllocationsClass,
} from './OptimizationConstraintsButton';
import type { OptimizationErrorType } from '../contexts/optimal-portfolio-context';
import { isNil } from 'lodash';
import { UserContext } from '../contexts';

export interface AllocationFooterProps {
  /** Current Portfolio */
  portfolio: Portfolio;
  selectedStrategyId: number;
  /** If there is nothing change yet, disable save button */
  canSave: boolean;
  canReset: boolean;
  onReset: () => void;
  onSave: (portfolio: Portfolio) => void;
  onSavedAsNewPortfolio?: (newPortfolio: Portfolio) => void;
  getSaveAsSuccessCustomNotification?: (portfolioName: string) => string;
  /**
   * The following props are for handling auto-scrolling the AllocationTree when we drag something below it.
   *
   * `showMouseEventsOverlay`: Buttons in disabled state don't handle mouse enter/leave events properly. To make scrolling
   * down on dragging over footer work, we need to show an overlay over the footer, that will correctly handle them.
   */
  showMouseEventsOverlay?: boolean;
  hasAllocationError?: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  isPercentageMode: boolean;
  baseAllocation: number;
  comparisonColor: string;
  showOptimizerConstraintsButton: boolean;
  onOptimizeSuccess?: (
    resultPortfolioId: number,
    resultSummary: PortfolioSummary,
    resultConfig: OptimizationConfiguration,
    resultPortfolio?: Portfolio,
  ) => void;
  optimizationError?: OptimizationErrorType;
  canRerunOptimization?: boolean;
  onRerunOptimization?: () => void;
  customAllocationConstraints?: AllocationConstraint[];
  hasComparison: boolean;
  onApplyComparisonAllocationsToCurrent?: () => void;
  hideComparisonColumn?: boolean;
  isStudio?: boolean;
}

const qaClasses = {
  reset: 'qa-reset',
  save: 'qa-save',
  saveAs: 'qa-save-as',
  toggle: 'qa-toggle',
};

const AllocationFooter = ({
  portfolio,
  selectedStrategyId,
  canSave,
  canReset,
  onReset,
  onSave,
  onSavedAsNewPortfolio,
  getSaveAsSuccessCustomNotification,
  showMouseEventsOverlay,
  onMouseEnter,
  onMouseLeave,
  hasAllocationError,
  isPercentageMode,
  baseAllocation,
  comparisonColor,
  showOptimizerConstraintsButton,
  onOptimizeSuccess,
  optimizationError,
  canRerunOptimization,
  onRerunOptimization,
  hasComparison,
  onApplyComparisonAllocationsToCurrent,
  hideComparisonColumn,
  isStudio,
}: AllocationFooterProps) => {
  const isMountedRef = useRef(false);
  const portfoliosContext = useContext(PortfoliosContext);
  const { hasPermissionForResource, hasPermission, canWriteToPortfolio } = useContext(UserContext);
  const { masterPortfolio } = portfoliosContext;
  const [isCreatePortfolioOpen, openCreatePortfolioModal, closeCreatePortfolioModal] = useModal();
  const [isMasterUpdateOpen, openMasterUpdateModal, closeMasterUpdateModal] = useModal();
  const [isSavingInProgress, setIsSavingInProgress] = useState<boolean>(false);
  const [populateNewPortfolioName, setPopulateNewPortfolioName] = useState<boolean>(false);

  useLayoutEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const onSavingFinished = useCallback(
    (saved: Portfolio | undefined) => {
      if (!isMountedRef.current) {
        return;
      }
      const isError = !saved;
      const isCustomSaveAs = !isNil(getSaveAsSuccessCustomNotification) && !isNil(saved) && saved.id !== portfolio.id;
      if (isError) {
        Notifications.notify('Saving failed', NotificationType.ERROR);
      } else {
        Notifications.notify(
          isCustomSaveAs ? getSaveAsSuccessCustomNotification(saved.name) : 'Successfully saved portfolio',
          NotificationType.SUCCESS,
        );
      }
      setIsSavingInProgress(false);
      if (!isError && saved) {
        onSave(saved);
        if (saved.id !== portfolio.id) {
          onSavedAsNewPortfolio?.(saved);
        }
      }
    },
    [onSave, setIsSavingInProgress, onSavedAsNewPortfolio, getSaveAsSuccessCustomNotification, portfolio.id],
  );

  const onSaveClick = useCallback(async () => {
    if (!isMountedRef.current) {
      return;
    }
    setIsSavingInProgress(true);

    if (portfolio?.remoteId || portfolio?.sourceId) {
      setPopulateNewPortfolioName(true);
      openCreatePortfolioModal();
      return;
    }

    if (portfolio?.master) {
      openMasterUpdateModal();
      return;
    }

    try {
      let updatedPortfolio = recursiveUpdatePortfolioName(portfolio);
      if (isPercentageMode && baseAllocation !== portfolio.allocation) {
        updatedPortfolio = recursiveUpdateAllocation(updatedPortfolio, baseAllocation);
      }
      updatedPortfolio = recursiveClearBenchmarks(updatedPortfolio);
      const newPortfolio = (await updatePortfolioV3(portfolio.id, updatedPortfolio)).content;
      onSavingFinished(newPortfolio);
    } catch (error) {
      logExceptionIntoSentry(error);
      onSavingFinished(undefined);
    }
  }, [portfolio, openCreatePortfolioModal, openMasterUpdateModal, isPercentageMode, baseAllocation, onSavingFinished]);

  const onSaveAsClick = () => {
    setPopulateNewPortfolioName(true);
    openCreatePortfolioModal();
  };

  const openSaveNewPortfolioModal = useCallback(() => {
    if (!isMountedRef.current) {
      return;
    }
    setIsSavingInProgress(true);
    setPopulateNewPortfolioName(false);
    openCreatePortfolioModal();
    closeMasterUpdateModal();
  }, [setIsSavingInProgress, openCreatePortfolioModal, closeMasterUpdateModal]);

  const onSavingCancelled = useCallback(
    (updateStateCallback: () => void) => () => {
      if (!isMountedRef.current) {
        return;
      }
      updateStateCallback();
      setIsSavingInProgress(false);
    },
    [setIsSavingInProgress],
  );

  const applyCompareAllocationsTooltip = hasComparison
    ? `Apply allocations to current ${selectedStrategyId === portfolio.id ? 'portfolio' : 'strategy'}`
    : `No comparison allocations found for the current ${
        selectedStrategyId === portfolio.id ? 'portfolio' : 'strategy'
      }`;
  const hasMaster = !!masterPortfolio;
  const isModelPortfolio = useMemo(
    () => portfolio.ownerContextId && !hasPermissionForResource('EDIT_PORTFOLIO', portfolio),
    [hasPermissionForResource, portfolio],
  );

  const showSaveButton = canWriteToPortfolio(portfolio) && hasMaster;

  const renderSaveButton = () =>
    showSaveButton ? (
      <SaveButton
        tooltipPosition={TooltipPosition.Top}
        tooltipMessage="Save Portfolio"
        showSaveButton={showSaveButton}
        disabled={!canSave || isSavingInProgress || hasAllocationError}
        onClick={() => {
          onSaveClick();
          analyticsService.ctaClicked({
            destination: undefined, // Stays on the page
            filled: false,
            locationOnPage: 'Allocation Panel - footer',
            purpose: 'Save allocation changes',
            text: 'Save',
            type: 'button',
          });
        }}
        className={qaClasses.save}
      >
        {isSavingInProgress ? <Spinner size={LoadingSize.small} /> : 'Save'}
      </SaveButton>
    ) : null;

  const newUserSetup = !hasMaster && isPortfolioEditable(portfolio);

  return (
    <StyledFooter onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {showMouseEventsOverlay && <MouseEventsOverlay onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} />}

      {portfolio.demo && (
        <WithComparisonColumn>
          <DemoLabel>
            <QuestionMark type="question-circle" /> {`You are viewing ${getAppTitle()}'s demo portfolio.`}&nbsp;
            <a href={DEMO_PORTFOLIO_FAQ_HREF} target="_blank" rel="noopener noreferrer">
              Learn more
            </a>
          </DemoLabel>
          {!hideComparisonColumn && <ComparisonColumn color={comparisonColor} />}
        </WithComparisonColumn>
      )}

      {showReadOnlyWarning(portfolio, !!isModelPortfolio) && (
        <WithComparisonColumn>
          <ReadOnlyLabel>
            <Icon type="pencil-slash" className="text-venn-highlight-dark" />
            {isModelPortfolio
              ? 'You do not have permission to edit Model portfolios. This portfolio will be saved separately.'
              : 'Portfolios synced from integrations cannot be edited. This portfolio will be saved separately.'}
          </ReadOnlyLabel>
          {!hideComparisonColumn && <ComparisonColumn color={comparisonColor} />}
        </WithComparisonColumn>
      )}

      <WithComparisonColumn>
        <FooterContainer disabled={!!showMouseEventsOverlay}>
          <FooterButton
            showSaveButton={showSaveButton}
            disabled={!canReset || isSavingInProgress}
            onClick={() => {
              onReset();
              analyticsService.ctaClicked({
                destination: undefined, // Stays on the page
                filled: false,
                locationOnPage: 'Allocation Panel - footer',
                purpose: 'Reset allocation changes',
                text: 'Reset',
                type: 'button',
              });
            }}
            className={qaClasses.reset}
          >
            Reset
          </FooterButton>
          {renderSaveButton()}
          <SaveAsButton
            tooltipPosition={TooltipPosition.Top}
            tooltipMessage={newUserSetup ? 'Save Portfolio' : 'Save Portfolio As...'}
            showSaveButton={showSaveButton}
            disabled={isSavingInProgress || hasAllocationError || !hasPermission('CREATE_PORTFOLIO')}
            onClick={() => {
              onSaveAsClick();
              analyticsService.ctaClicked({
                destination: undefined, // Stays on the page
                filled: false,
                locationOnPage: 'Allocation Panel - footer',
                purpose: 'Save allocations as new portfolio',
                text: newUserSetup ? 'Save' : 'Save as...',
                type: 'button',
              });
            }}
            className={qaClasses.saveAs}
          >
            {newUserSetup ? 'Save' : 'Save as...'}
          </SaveAsButton>
        </FooterContainer>

        {!hideComparisonColumn && showOptimizerConstraintsButton && onOptimizeSuccess ? (
          <OptimizationConstraintsButton
            portfolio={portfolio}
            error={optimizationError}
            showRefresh={canRerunOptimization}
            onRefresh={onRerunOptimization}
            hasComparison={hasComparison}
            applyCompareAllocationsTooltip={applyCompareAllocationsTooltip}
            onApplyComparisonAllocationsToCurrent={onApplyComparisonAllocationsToCurrent}
          />
        ) : !hideComparisonColumn && !isNil(onApplyComparisonAllocationsToCurrent) ? (
          <Tooltip content={applyCompareAllocationsTooltip} usePortal portalPosition={{ top: 30 }} block>
            <ComparisonColumnButton
              color={comparisonColor}
              onClick={!hasComparison ? undefined : onApplyComparisonAllocationsToCurrent}
              disabledStyle={!hasComparison}
              className={qaApplyAllocationsClass}
            >
              <Icon type="sign-out-alt" />
            </ComparisonColumnButton>
          </Tooltip>
        ) : !hideComparisonColumn ? (
          <ComparisonColumn color={comparisonColor} />
        ) : null}
      </WithComparisonColumn>

      {isCreatePortfolioOpen && (
        <CreateMasterPortfolioModal
          portfolio={portfolio}
          onClose={onSavingCancelled(closeCreatePortfolioModal)}
          onSubmitCallback={onSavingFinished}
          isPercentageMode={isPercentageMode}
          total={baseAllocation}
          populateName={populateNewPortfolioName}
          isStudio={isStudio}
        />
      )}
      {isMasterUpdateOpen && (
        <UpdateMasterPortfolioModal
          portfolio={portfolio}
          openSaveNewPortfolioModal={openSaveNewPortfolioModal}
          onClose={onSavingCancelled(closeMasterUpdateModal)}
          onSubmitCallback={onSavingFinished}
          isPercentageMode={isPercentageMode}
          total={baseAllocation}
        />
      )}
    </StyledFooter>
  );
};

export default AllocationFooter;

const StyledFooter = styled(Footer)`
  display: block;
  font-size: 1rem;
`;

const QuestionMark = styled(Icon)`
  margin-right: 4px;
`;

const FooterHeight = css`
  height: 40px;
`;

const MouseEventsOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const FooterContainer = styled.div<{ disabled: boolean }>`
  ${FooterHeight};
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-right: none;

  ${({ disabled }) => disabled && 'pointer-events: none !important;'}
`;

const Message = styled.div`
  flex: 1;
`;

const DemoLabel = styled(Message)`
  height: 40px;
  background-color: ${GetColor.WhiteGrey};
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid ${GetColor.GreyScale.Grey30};

  > a {
    font-weight: bold;
  }
`;

const ReadOnlyLabel = styled(Message)`
  background-color: ${GetColor.HighlightPaleBackground};
  padding: 12px 10px;
  display: flex;
  gap: 4px;
  align-items: center;
`;

export const FooterButton = styled(ToolbarButton)<{ showSaveButton?: boolean }>`
  ${FooterHeight};
  margin: 0;
  min-width: 40px;
  width: ${({ showSaveButton }) => (showSaveButton ? 61 : 112) + Constants.HISTORY_BUTTON_WIDTH}px;
  text-align: center;
  font-size: 11px;
  border-radius: 0;
  border-top: solid 1px ${GetColor.Grey};
  border-left: none;
  border-bottom: none;
  border-right: solid 1px ${GetColor.Grey};
  outline: none;
  color: ${GetColor.Black};
  font-weight: bold;
  padding: 0;

  &:last-child {
    border-right: none;
  }

  &:hover {
    color: ${GetColor.DarkGrey};
  }
`;

export const SaveButton = styled(FooterButton)`
  color: ${GetColor.Primary.Dark};
  &:hover {
    color: ${GetColor.Primary.Main};
  }
`;

export const SaveAsButton = styled(SaveButton)`
  border-right: none;
  width: ${({ showSaveButton }) => (showSaveButton ? 63 : 113) + Constants.HISTORY_BUTTON_WIDTH}px;
`;

const ComparisonColumn = styled.div<{ color: string }>`
  width: 105px;
  background-color: ${({ color }) => ColorUtils.hex2rgba(color, 0.05)};
`;

// Tooltips that use portals have issues with disabled buttons (they're not disappearing).
// This is a global ReactJS problem; see https://github.com/ant-design/ant-design/issues/18137
// Using `disabledStyle` instead of `disabled ` and passing `undefined` to `onClick` is a workaround.
const ComparisonColumnButton = styled(CompareColumnButton)<{ color: string; disabledStyle?: boolean }>`
  width: 105px;
  background-color: ${({ color }) => color};
  i {
    transform: rotate(180deg);
    color: ${GetColor.White};
    font-size: 15px;
    margin-top: 1px;
  }

  ${({ disabledStyle }) =>
    disabledStyle &&
    css`
      border-color: ${GetColor.HintGrey};
      background-color: ${GetColor.HintGrey};
      cursor: not-allowed;
    `}
`;

const WithComparisonColumn = styled.div`
  display: flex;
`;
