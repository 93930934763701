import React from 'react';
import styled, { css } from 'styled-components';
import { GetColor } from 'venn-ui-kit';
import { useHasFF } from 'venn-utils';
import { Constants } from '../Layout';
import StudioAllocatorSubjectSelector from './StudioAllocatorSubjectSelector';
import { useRecoilState, useRecoilValue } from 'recoil';
import { compareColumnOpen, isReportState } from 'venn-state';
import { ClosePanelButton } from '../../side-panel-settings/ClosePanelButton';

interface StudioAllocatorSubjectProps {
  width: number;
  disabled?: boolean;
  isPrivate?: boolean;
  hideCompareLink?: boolean;
  onClose?: () => void;
}

export const StudioAllocatorSubject = ({
  disabled,
  isPrivate = false,
  hideCompareLink = false,
  onClose,
}: StudioAllocatorSubjectProps) => {
  const isReport = useRecoilValue(isReportState);
  return (
    <StudioAllocatorSubjectSection>
      <div className="flex items-center justify-between gap-[16px]">
        <StudioAllocatorSubjectSelector disabled={disabled} />
        <ClosePanelButton onClose={onClose} />
      </div>
      {!isPrivate && !isReport && !hideCompareLink && <CompareLink disabled={disabled} />}
    </StudioAllocatorSubjectSection>
  );
};

const CompareLink = ({ disabled }: { disabled?: boolean }) => {
  const [compareOpen, setCompareOpen] = useRecoilState(compareColumnOpen);
  const onLinkClick = () => setCompareOpen(!compareOpen);

  return (
    <div style={{ textAlign: 'right' }}>
      <LinkText onClick={onLinkClick} disabled={disabled}>
        {compareOpen ? 'Hide Compare' : 'Compare Versions'}
      </LinkText>
    </div>
  );
};

interface ShimmerPanelProps {
  isPrivate?: boolean;
}
export const StudioAllocationPanelShimmerBlock = ({ isPrivate }: ShimmerPanelProps) => {
  const hasApInRl = useHasFF('ap_in_rl_ff');
  return (
    <StudioAllocatorWindow hasApInRl={hasApInRl}>
      <StudioAllocatorSubject isPrivate={isPrivate} width={Constants.ALLOCATION_PANEL_TOTAL_WIDTH + 1} disabled />
    </StudioAllocatorWindow>
  );
};

export const StudioAllocatorWindow = styled.div<{
  hasApInRl: boolean;
}>`
  height: 100%;
  background-color: ${GetColor.White};
  ${({ hasApInRl }) =>
    hasApInRl
      ? css`
          border-left: 1px solid ${GetColor.GreyScale.Grey30};
          border-right: 3px solid ${GetColor.GreyScale.Grey30};
        `
      : css`
          border-left: 1px solid ${GetColor.Grey};
          border-right: 1px solid ${GetColor.Grey};
        `}

  overflow: hidden;
  position: relative;
  display: grid;
  grid-template-areas:
    'allocator-header'
    'allocator-subject'
    'allocator-panel';
  grid-template-columns: 1fr;
  grid-template-rows: 0 auto 1fr;
`;

export const EmptyStudioAllocatorWindow = styled(StudioAllocatorWindow)`
  /* The empty allocator doesn't have a separate header. */
  grid-template-areas:
    'allocator-subject'
    'allocator-panel';
  grid-template-rows: auto 1fr;
  width: 100%;
`;

export const StudioAllocatorSubjectSection = styled.div`
  grid-area: allocator-subject;
  padding: 12px 8px;
  background-color: ${GetColor.White};
  width: 100%;
`;

const LinkText = styled.button`
  color: ${GetColor.Primary.Dark};
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  margin-top: 8px;
`;
